import React, { Component } from 'react';
import { Link } from "gatsby";
import { Layout, SEO, Image, HeroBanner, TestimonialSlider, CaseStudies, TechnologiesStack } from '../../components';
import { validateArray } from '../../components/utilities/functions';
import AOS from 'aos';
import CustomScroll from 'react-custom-scroll';

import './index.scss';

class ServicePage extends Component {

	componentDidMount() {
		AOS.init({
			duration: 1000,
			disable: 'mobile',
			once: true
		})
	}

	render() {

		// const { data } = this.props;
		const { pageContext } = this.props;
		const { uid = "", locale = "", hero, url, seo, services_info, technologies, we_offer, way_of_work, case_studies, testimonials } = (pageContext.node) || {};

		const iconDetail = (item) => {
			return (<>
				{
					item.icon &&
					<div className='icon-image'>
						<Image
							data={{ image: item.icon, image_alt: item.title }}
							resizeWidth={80}
							format={false}
						/>
					</div>
				}
				{
					item.title &&
					<span className='icon-title'>{item.title}</span>

				}
			</>
			)
		}

		const extension = {
			contenttype:(pageContext?.contentType || ''),
			uid:uid,
			locale:locale
		}

		return (

			<Layout className='services-wrapper animation-wrapper' pageURL={url}>
				<SEO
					pageURL={url}
					{...seo}
					extension={extension}
				/>
				{
					hero &&
					<HeroBanner
						image={hero.hero_image}
						imageAlt={hero.image_alt}
						heading={hero.heading}
						hasOverlayBG={true}
					/>
				}

				{
					services_info &&
					<div className='services-info box-10080  text-center'>
						<div className='container container--980'>
							{
								services_info.heading &&
								<h2 className='title-lineover'>{services_info.heading}</h2>
							}
							{
								services_info.description &&
								<div className='author-quote p__body1' dangerouslySetInnerHTML={{ __html: services_info.description }} />
							}

						</div>
					</div>
				}
				{
					we_offer &&
					<div className='services-offered bg-grey'>
						<div className='container'>
							{
								we_offer.heading &&
								<h2 className='title-lineover'>{we_offer.heading}</h2>
							}
							{
								validateArray(we_offer.services) &&
								<div className='serv-offered-row'>
									{
										we_offer.services.map((item, index) =>
											<div className='text-center serv-offered-col' key={index}>
												{
													(item.link &&
														<Link to={item.link}>
															{iconDetail(item)}
														</Link>
													) ||
													iconDetail(item)
												}
											</div>
										)}
								</div>
							}
						</div>
					</div>
				}
				{
					validateArray(testimonials) &&
					<TestimonialSlider data={testimonials} />
				}

				{
					way_of_work &&
					<div className='way-of-work box-100  bg-grey'>
						<div className='container'>
							{
								way_of_work.heading &&
								<h2 className='title-lineover'>{way_of_work.heading}</h2>
							}
							{
								validateArray(way_of_work.steps) &&
								<div className='row wayOfWork-section'>
									{
										way_of_work.steps.map((item, index) =>
											<div key={index} className={'steps-col text-center col-12 col-768-6 col-992-4'}>
												<div className={'steps-card'} key={index}>
													{
														item.icon &&
														<div className='steps-icon' data-aos="zoom-in" data-aos-duration="900">
															<Image
																data={{ image: item.icon, image_alt: item.image_alt }}
																format={false}
																resizeWidth={115}
															/>
														</div>
													}
													{
														item.heading &&
														<h3 className='icon-title' data-aos="fade-up" data-aos-duration="900">{item.heading}</h3>
													}
													<div className='card-content'>
														<CustomScroll heightRelativeToParent='100%'>

															{
																item.heading &&
																<h3 className='icon-title'>{item.heading}</h3>
															}
															{
																item.description &&
																<div dangerouslySetInnerHTML={{ __html: item.description }} />
															}
														</CustomScroll>
													</div>
												</div>
											</div>
										)
									}
								</div>
							}
						</div>
					</div>
				}
				{
					technologies &&
					<div className='technologies box-100 text-center has-bottom-border'>
						<div className='container container--980'>
							{
								technologies.heading &&
								<h2 className='title-lineover'>{technologies.heading}</h2>
							}
							{
								technologies.description &&
								<div className='p__body1' dangerouslySetInnerHTML={{ __html: technologies.description }} />
							}
							{
								validateArray(technologies.technologies_list) &&
								<TechnologiesStack data={technologies.technologies_list} hasOpacity />
							}
						</div>
					</div>
				}

				{
					case_studies &&
					validateArray(case_studies.case_studies_list) &&
					<CaseStudies
						data={case_studies.case_studies_list}
						heading={case_studies.heading}
						headingClass='title-linethrough'
						className='bg-grey box-100'
					/>
				}
			</Layout>
		)
	}
}

export default ServicePage;
